import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import logo from '@/assets/logo.png';
export default {
  name: "systems",
  computed: {
    ...mapGetters({
      menus: 'perm/getMenus'
    })
  },
  data() {
    return {
      logo: logo,
      defaultIcon: 'icon-menu'
    };
  },
  methods: {
    enterSystem(item) {
      // permissionCode
      const first = this.$store.getters['perm/getFirstMenu']([item]);
      if (first) {
        if (first.permissionCode.indexOf(".html") > 0) {
          window.open(first.permissionCode, '_self');
        } else {
          sessionStorage.setItem('currentMenuId', item.permissionId);
          this.$router.push(first.permissionCode);
        }
      }
    }
  }
};
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f31e6944"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = {
  class: "systems"
};
const _hoisted_3 = {
  class: "logo"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "system"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "system-item-icon"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "system-item-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    src: $data.logo
  }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.productName), 1)]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, item => {
    var _item$icon;
    return _openBlock(), _createElementBlock("div", {
      key: item.permissionId,
      class: "system-item",
      onClick: $event => $options.enterSystem(item)
    }, [_createElementVNode("div", _hoisted_8, [item.icon && item.icon.startsWith('http') ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: item.icon
    }, null, 8, _hoisted_9)) : (_openBlock(), _createBlock(_component_el_icon, {
      key: 1,
      class: _normalizeClass(["iconfont", (_item$icon = item.icon) !== null && _item$icon !== void 0 ? _item$icon : $data.defaultIcon])
    }, null, 8, ["class"]))]), _createElementVNode("div", _hoisted_10, _toDisplayString(item.permissionName), 1)], 8, _hoisted_7);
  }), 128))])])]);
}